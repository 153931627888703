/**
 * Data for seo-queries
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * TODO: Use wordpress-file-props
 */
import PropTypes from 'prop-types';

const SeoShape = {
  title: PropTypes.string,
  canonical: PropTypes.string,
  metaDesc: PropTypes.string,
  metaRobotsNoindex: PropTypes.string,
  metaRobotsNofollow: PropTypes.string,
  opengraphTitle: PropTypes.string,
  opengraphDescription: PropTypes.string,
  opengraphImage: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
    gatsbyFileUrl: PropTypes.string,
  }),
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
    gatsbyFileUrl: PropTypes.string,
  }),
};

export default SeoShape;

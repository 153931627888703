/**
 * Our footer-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import * as styles from './footer.module.scss';
import FooterMenu from './FooterMenu/FooterMenu';
import CookieConsent from '../CookieConsent';
import {Globals} from '../../utilities/Store';

const Footer = () => {
    const {themeSettings: {copyright}} = React.useContext(Globals);
    return (<>
        <footer className={styles.footer}>
            <FooterMenu/>
            <div className={styles.copyright}>
                &copy; {new Date().getFullYear()} {copyright}
            </div>
        </footer>
        <CookieConsent/>
    </>);
};

export default Footer;

/**
 * Our link from the template
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * TODO: Hash
 */
// NPM
import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

// Peritus
import PeritusLinkProps from './PeritusLink.props';
import {CreateLocalLink} from 'utilities';

const PeritusLink = (props) => {
    let {
        className,
        activeClassName,
        linktype,
        internalurl,
        termUrl,
        externalurl,
        fileurl,
        hash,
        target,
        children
    } = props;

    let url = '',
        title = '',
        externalLink = target;

    switch (linktype) {
        case 'internal':
        case 'term':
            url = 'internal' === linktype && internalurl && internalurl.uri
                ? internalurl.uri
                : (termUrl && termUrl.uri ? termUrl.uri : false)

            if (!url) {
                externalLink = true;
                url = '#';
                break;
            }

            url = CreateLocalLink(url);
            externalLink = !!target || !!hash;
            url += hash ? hash : '';

            break;
        case 'file':
            externalLink = true;
            url = fileurl.gatsbyFileUrl || fileurl.mediaItemUrl;
            title = fileurl.title;
            break;
        case 'external':
        default:
            externalLink = true;
            url = CreateLocalLink(externalurl, false, false);
            break;
    }

    target = externalLink ? '_blank' : '';

    if (externalLink) {
        return (<a href={url} className={className} title={title} target={target}>{children}</a>);
    }

    return (<Link to={url} className={className} activeClassName={activeClassName}>{children}</Link>);
};

PeritusLink.propTypes = {
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    ...PeritusLinkProps
};

PeritusLink.defaultProps = {
    className: '',
    activeClassName: '',
    target: false,
    hash: '',
};

export default PeritusLink;

/**
 * Seo-Component
 *
 * @author David Faber
 * @copyright Peritus Webdesign GmbH
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import classNames from 'classnames';
import {Globals} from 'utilities/Store';

const Seo = ({seoData, translations, language, url}) => {
    const {seo, defaultLanguage} = React.useContext(Globals);
    const lang = language && language.slug || seo.lang;
    url = `${seo.siteUrl}${url}`;


    const bodyClasses = classNames({
        // TODO: no-scroll when menu is open
    });

    const googleVerify = seo.webmaster.googleVerify ? [{
        name: 'google-site-verification',
        content: seo.webmaster.googleVerify,
    }] : [];

    return (
        <Helmet
            htmlAttributes={{
                lang: lang,
            }}
            bodyAttributes={{
                class: bodyClasses,
            }}
            title={seoData.title || seo.schema.siteName}
            link={[
                {
                    rel: 'canonical',
                    href: seoData.canonical || url || '',
                },
                {
                    rel: 'alternate',
                    href: url,
                    hreflang: lang,
                },
                ...translations.map((translation) => {
                    const langLink = translation.uri && '/' !== translation.uri
                        ? translation.uri
                        : (
                            translation.language.slug !== defaultLanguage.slug
                                ? `/${translation.language.slug}/`
                                : '/'
                        );

                    return translation.language && translation.language.slug ? {
                        rel: 'alternate',
                        href: `${seo.siteUrl}${langLink}`,
                        hreflang: translation.language.slug,
                    } : null;
                }),
            ]}
            meta={[
                ...googleVerify,
                {
                    name: 'MobileOptimized',
                    content: '480',
                },
                {
                    name: 'description',
                    content: seoData.metaDesc || '',
                },


                // OG:Data
                {
                    property: 'og:title',
                    content: seoData.opengraphTitle || seoData.title || seo.schema.siteName,
                },
                {
                    property: 'og:description',
                    content: seoData.opengraphDescription || seoData.metaDesc || '',
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:site_name',
                    content: seo.schema.siteName,
                },
                {
                    property: 'og:locale',
                    content: lang,
                },
                ...translations.map((translation) => {
                    return translation && translation.language && translation.language.slug ? {
                        property: 'og:locale:alternate',
                        content: translation.language.slug,
                    } : null;
                }),
                {
                    name: 'og:image',
                    content:
                        // Share-Image
                        seoData.opengraphImage && seoData.opengraphImage.gatsbyFileUrl
                        || seoData.opengraphImage && seoData.opengraphImage.mediaItemUrl
                        // Yoast Default-Image
                        || seo.social.facebook.defaultImage
                        && seo.social.facebook.defaultImage.gatsbyFileUrl
                        || seo.social.facebook.defaultImage
                        && seo.social.facebook.defaultImage.mediaItemUrl
                        // TODO: Post-Thumb
                        || '',
                },
                {
                    name: 'og:image:alt',
                    content:
                        seoData.opengraphImage && seoData.opengraphImage.altText
                        || seoData.title
                        || seo.schema.siteName,
                },


                // Twitter:Data
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    name: 'twitter:creator',
                    content: seo.schema.companyName || seo.schema.siteName,
                },
                {
                    name: 'twitter:title',
                    content: seoData.twitterTitle || seoData.title || seo.schema.siteName,
                },
                {
                    name: 'twitter:description',
                    content: seoData.twitterDescription || seoData.metaDesc || '',
                },
                {
                    name: 'twitter:image',
                    content:
                        // Share-Image
                        seoData.twitterImage && seoData.twitterImage.gatsbyFileUrl
                        || seoData.twitterImage && seoData.twitterImage.mediaItemUrl
                        // Yoast Default-Image
                        || seo.social.facebook.defaultImage
                        && seo.social.facebook.defaultImage.gatsbyFileUrl
                        || seo.social.facebook.defaultImage
                        && seo.social.facebook.defaultImage.mediaItemUrl
                        // TODO: Post-Thumb
                        || '',
                },
                {
                    name: 'twitter:image:alt',
                    content:
                        seoData.twitterImage && seoData.twitterImage.altText
                        || seoData.title
                        || seo.schema.siteName,
                },
            ]}
        />
    );
};

Seo.propTypes = {
    seoData: PropTypes.shape({
        title: PropTypes.string,
        canonical: PropTypes.string,
        metaDesc: PropTypes.string,
        metaRobotsNoindex: PropTypes.string,
        metaRobotsNofollow: PropTypes.string,
        opengraphTitle: PropTypes.string,
        opengraphDescription: PropTypes.string,
        opengraphImage: PropTypes.shape({
            altText: PropTypes.string,
            sourceUrl: PropTypes.string,
            gatsbyFileUrl: PropTypes.string,
        }),
        twitterTitle: PropTypes.string,
        twitterDescription: PropTypes.string,
        twitterImage: PropTypes.shape({
            altText: PropTypes.string,
            sourceUrl: PropTypes.string,
            gatsbyFileUrl: PropTypes.string,
        }),
    }).isRequired,
    language: PropTypes.shape({
        slug: PropTypes.string,
    }),
    translations: PropTypes.arrayOf(PropTypes.shape({
        uri: PropTypes.string,
        language: PropTypes.shape({
            slug: PropTypes.string,
        }),
    })),
    url: PropTypes.string,
};

export default Seo;

/**
 * Data for seo-queries
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * TODO: Use wordpress-file-props
 */
import PropTypes from 'prop-types';

const LangShape = {
    language: PropTypes.shape({
        slug: PropTypes.string,
    }),
    translations: PropTypes.arrayOf(PropTypes.shape({
        uri: PropTypes.string,
        language: PropTypes.shape({
            slug: PropTypes.string,
        }),
    })),
};

export default LangShape;

/**
 * Proptypes for responsive-images
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import PropTypes from 'prop-types';

const ResponsiveImageProps = {
    sourceUrl: PropTypes.string.isRequired,
    mediaItemUrl: PropTypes.string.isRequired,
    gatsbyFileUrl: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
    imageFile: PropTypes.object,
};

export default ResponsiveImageProps;

/**
 * Our main header-logo component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import Logo from './logo.inline.svg'
import {Link} from 'gatsby';
import * as styles from './main-logo.module.scss';

const MainLogo = ({siteTitle, url}) => (
    <Link className={styles.mainLogo} to={url}>
        <Logo className={styles.logo}/>
        {siteTitle}
    </Link>
);

export default MainLogo;

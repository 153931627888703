// extracted by mini-css-extract-plugin
export var block = "block-module--block--y6zDm";
export var wide = "block-module--wide--igmgg";
export var full = "block-module--full--iIznE";
export var cover = "block-module--cover--HMq-d";
export var layout = "block-module--layout--YsSgs";
export var bggrey = "block-module--bggrey--CRFTo";
export var bgsvg = "block-module--bgsvg--WejDl";
export var svgContainer = "block-module--svg-container--fbUj4";
export var theSeven = "block-module--the-seven--FRR7y";
export var bgNot = "block-module--bgNot--B9tEW";
export var grey = "block-module--grey--AijnV";
export var image = "block-module--image--pht4Y";
/**
 * A general content-block component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Peritus
import BlockShape from './Block.props';

// Styles
import * as styles from './block.module.scss';

// Assets
import SystemLogo from 'images/system7.inline.svg';

const Block = ({Parent, children, rowSettings, ...props}) => {
    const parentClasses = classNames({
        [styles.block]: true,
        [props.className]: !!props.className,
        [styles[`bg${rowSettings.background}`]]: !!rowSettings.background && 'default' !== rowSettings.background,
    });

    const parentProps = {};

    if (rowSettings && rowSettings.rowId) {
        parentProps.id = rowSettings.rowId;
    }

    return (<Parent className={parentClasses}>
        {children}
        {rowSettings.background === 'svg' &&
        <div className={styles.svgContainer}><SystemLogo className={styles.theSeven}/></div>}
    </Parent>)
};

Block.propTypes = {
    Parent: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    ...BlockShape,
};

Block.defaultProps = {
    Parent: 'div',
    rowSettings: {
        background: 'default',
    },
};

export default Block;

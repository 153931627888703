/**
 * Our button-row-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PeritusLink, {PeritusLinkProps} from '../PeritusLink';
import * as styles from './button.module.scss'

const ButtonLink = ({link, style, size, extraClasses}) => {
    const buttonClasses = classNames({
        [styles.button]: true,
        [styles[style]]: !!styles[style],
        [styles[size]]: !!styles[size],
        [extraClasses]: !!extraClasses,
    });

    return (<PeritusLink className={buttonClasses} {...link}>{link.linktext}</PeritusLink>)
};

ButtonLink.propTypes = {
    link: PropTypes.shape(PeritusLinkProps).isRequired,
    style: PropTypes.string,
    size: PropTypes.string,
};

ButtonLink.defaultProps = {
    style: '',
    size: '',
};

export default ButtonLink;

/**
 * Our helper for translations
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */

import React from 'react';
import {Globals} from 'utilities/Store';

/**
 * Get string-translations for string-id
 *
 * @param {string} id
 *
 * @returns {string} The string
 */
const Translate = (id) => {
    const {translations} = React.useContext(Globals);

    return translations[id] ? translations[id] : 'Unrecognized string-translation';
};

export default Translate;

/**
 * Component for our cookie-consent
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React, {Component} from 'react';
import cookie from 'react-cookies';
import PeritusLink from '../PeritusLink';
import {Button, TextButton} from '../Button';
import * as styles from './cookie-consent.module.scss';
import {Globals} from '../../utilities/Store';

class CookieConsent extends Component {
    static contextType = Globals;

    constructor(props, context) {
        super(props, context);
        this.cookieName = 'cookie-consent';

        let hasCookie = true;
        let consentData = cookie.load(this.cookieName);

        const {
            gdprSettings: {
                cookieConsent: {moreInformation},
                cookieTypes,
            },
        } = this.context;

        this.excludedPages = moreInformation.map((link) => {
            return 'internal' === link.linktype ? `/${link.internalurl.uri}` : false;
        });

        this.cookieTypes = cookieTypes;

        if (!consentData) {
            hasCookie = false;
            consentData = {};
            for (const type in this.cookieTypes) {
                consentData[this.cookieTypes[type].slug] = 'necessary' === this.cookieTypes[type].slug;
            }
        }

        this.state = {
            hasCookie: hasCookie,
            showDetails: false,
            cookieConsent: consentData,
            cookieConsentVisible: false,
        };

        this.pushSettingsToDataLayer();
    }

    /**
     * Prepare the dataLayer
     */
    componentDidMount() {
        window.dataLayer = window.dataLayer || [];

        if (!this.state.hasCookie) {
            this.setConsentVisibility(this.isApplicable());
        }
    }

    /**
     * Set the visibility for the cookie-consent
     *
     * @param {boolean} visibility
     */
    setConsentVisibility(visibility) {
        this.setState({
            cookieConsentVisible: visibility,
        });
    }

    /**
     * Update a single option
     * @param {string} slug
     */
    updateOption(slug) {
        this.setState({
            cookieConsent: {
                ...this.state.cookieConsent,
                [slug]: !this.state.cookieConsent[slug],
            },
        });
    }

    /**
     * Show/Hide detailed information
     */
    toggleDetails() {
        this.setState({showDetails: !this.state.showDetails});
    }

    /**
     * Select all the options, save and close
     */
    selectAll() {
        let typeSlugs = {};
        for (const type in this.cookieTypes) {
            typeSlugs[this.cookieTypes[type].slug] = true;
        }
        this.setState({cookieConsent: typeSlugs}, this.saveSelection);
    }

    /**
     * Save current state and close
     */
    saveSelection() {
        this.setConsentVisibility(false);
        cookie.save(this.cookieName, this.state.cookieConsent, {path: '/'});
        this.pushSettingsToDataLayer();
        this.setState({hasCookie: true});
    }

    /**
     * Pushes the cookie-settings to the DataLayer
     */
    pushSettingsToDataLayer() {
        if (typeof window === 'undefined') {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'cookie-consent',
            cookieSettings: this.state.cookieConsent,
        });

        if (!this.state.hasCookie) {
            window.dataLayer.push({event: 'gatsby-route-change'});
        }
    }

    /**
     * Should the cookiebar be shown?
     *
     * @return {boolean}
     */
    isApplicable() {
        return typeof window !== 'undefined' ? !this.isBot() && !this.currentPageIsExcluded() : false;
    }

    /**
     * Check if the current user is a bot
     *
     * @return {boolean}
     */
    isBot() {
        return window.navigator
            && /bot|googlebot|crawler|spider|robot|crawling|lighthouse/i.test(window.navigator.userAgent);
    }

    /**
     * Check if the current page is an excluded one
     *
     * @return {boolean}
     */
    currentPageIsExcluded() {
        return this.excludedPages.includes(window.location.pathname);
    }

    render() {
        if (!this.state.cookieConsentVisible) {
            return '';
        }

        const {
            gdprSettings: {
                cookieConsent: {
                    title,
                    text,
                    textAccept,
                    textMoreInfo,
                    textSave,
                    moreInformation,
                },
                cookieTypes,
            },
        } = this.context;

        const details = this.state.showDetails
            ? (<dl>
                {
                    cookieTypes.map((type, key) => (
                        <React.Fragment key={key}>
                            <dt>{type.name}</dt>
                            <dd dangerouslySetInnerHTML={{__html: type.description}}/>
                        </React.Fragment>
                    ))
                }
            </dl>) : '';

        return (
            <div className={styles.cookieConsent}>
                <div className={styles.inner}>
                    <div className={styles.title} dangerouslySetInnerHTML={{__html: title}}/>
                    <div dangerouslySetInnerHTML={{__html: text}}/>
                    <ul className={styles.more}>
                        <li className={styles.moreEl}>{`${textMoreInfo} `} </li>
                        {
                            moreInformation && moreInformation.map((link, key) => (
                                <li key={key} className={styles.moreEl}>
                                    <PeritusLink {...link}>
                                        {link.linktext}
                                    </PeritusLink>
                                </li>
                            ))
                        }
                    </ul>
                    <div className={styles.options}>
                        {
                            cookieTypes.map((type, key) => {
                                let inputProps = {
                                    id: type.slug,
                                    name: type.slug,
                                    className: 'checkbox',
                                    type: 'checkbox',
                                    disabled: 'necessary' === type.slug,
                                    checked: this.state.cookieConsent[type.slug],
                                    onChange: 'necessary' !== type.slug ? () => this.updateOption(type.slug) : null,
                                };

                                return (<div key={key} className={styles.option}>
                                    <input {...inputProps}/>
                                    <label className={'checkbox-label'} htmlFor={type.slug}>{type.name}</label>
                                </div>);
                            })
                        }
                    </div>
                    <div className={styles.details}>
                        <TextButton
                            buttonText={'Details'}
                            extraClasses={'button-link'}
                            onClick={() => this.toggleDetails()}
                        />
                        {details}
                    </div>
                    <div className={styles.buttons}>
                        <Button buttonText={textAccept} onClick={() => this.selectAll()}/>
                        <TextButton
                            buttonText={textSave}
                            onClick={() => this.saveSelection()}
                            extraClasses={styles.save}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CookieConsent;

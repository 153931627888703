/**
 * Our menu-link component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import {CreateLocalLink} from '../../utilities';
import {Link} from 'gatsby';
import ArrowIcon from './arrow.inline.svg';

const MenuLink = ({hasSubmenu, className, activeClass, url, label, target, ...linkAttributes}) => {
    // Handling external links
    const link = CreateLocalLink(url);

    target = link.startsWith('http') || link.includes('/fs/')
        ? '_blank'
        : target;

    return target
        ? <a
            href={link}
            target={target}
            className={className}
            {...linkAttributes}
        >
            {label}
            {hasSubmenu ? <ArrowIcon/> : null}
        </a>
        : <Link
            className={className}
            to={link}
            activeClassName={activeClass}
            partiallyActive={true}
            {...linkAttributes}
        >
            {label}
            {hasSubmenu ? <ArrowIcon/> : null}
        </Link>;
};

MenuLink.propTypes = {
    className: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    activeClass: PropTypes.string.isRequired,
    hasSubmenu: PropTypes.bool,
    target: PropTypes.string
};

MenuLink.defaultProps = {
    hasSubmenu: false,
    target: '',
};

export default MenuLink;

/**
 * Our basic menu-item
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React, {Component} from 'react';
import classNames from 'classnames';
import * as styles from './menu-item.module.scss';
import PropTypes from 'prop-types';
import Submenu from './Submenu';
import MenuLink from '../../MenuLink/MenuLink';

class MenuItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tapped: false,
            hovered: false,
        };
    }

    toggleSubmenu() {
        this.setState({tapped: !this.state.tapped});
    }

    setHover(state) {
        this.setState({hovered: state});
    }

    render() {
        const {
            childItems,
            label,
            url,
            depth,
            target,
            extraClasses,
        } = this.props;

        let Children = '',
            linkAttributes = {};

        const hasSubmenu = !!childItems.nodes.length;

        const linkClassNames = classNames({
            [styles[`depth${depth}`]]: true,
            [styles.tapped]: this.state.tapped,
        });

        const itemClassNames = classNames({
            [styles.menuItem]: true,
            [extraClasses]: !!extraClasses,
        });

        if (childItems.nodes.length) {
            Children = <Submenu
                nodes={childItems.nodes}
                depth={depth}
                tapped={this.state.tapped}
                hovered={this.state.hovered}
                parent={{
                    label: label,
                    url: url,
                    target: target,
                }}
            />;
            linkAttributes = {
                onTouchStart: this.toggleSubmenu.bind(this),
                onTouchEnd: e => e.preventDefault(),
            };
        }

        return (
            <li
                className={itemClassNames}
                onMouseEnter={e => this.setHover(true)}
                onMouseLeave={e => this.setHover(false)}
            >
                <MenuLink
                    hasSubmenu={hasSubmenu}
                    className={linkClassNames}
                    activeClass={styles.active}
                    url={url}
                    label={label}
                    target={target}
                    {...linkAttributes}/>
                {Children}
            </li>
        );
    }
}

MenuItem.propTypes = {
    hasSubmenu: PropTypes.bool,
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    depth: PropTypes.number,
    id: PropTypes.string,
    target: PropTypes.string,
    childItems: PropTypes.object,
    extraClasses: PropTypes.string,
};

MenuItem.defaultProps = {
    target: '',
    extraClasses: '',
    childItems: {nodes: []},
    depth: 0,
};

export default MenuItem;

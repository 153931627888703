/**
 * Our submenu-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './submenu.module.scss';
import MenuItem from './MenuItem';

const Submenu = ({parent, nodes, depth, tapped, hovered}) => (
    <ul className={`${styles.submenu} ${tapped ? styles.tapped : ''} ${hovered ? styles.hovered : ''}`}>
        <MenuItem extraClasses={styles.parent} depth={depth + 1} {...parent}/>
        {
            nodes.length && nodes.map((menuItem, index) => (
                <MenuItem key={index} {...menuItem} depth={depth + 1}/>
            ))
        }
    </ul>
);

Submenu.propTypes = {
    parent: PropTypes.object.isRequired,
    nodes: PropTypes.array.isRequired,
    depth: PropTypes.number.isRequired,
    tapped: PropTypes.bool.isRequired,
    hovered: PropTypes.bool.isRequired,
};

export default Submenu;

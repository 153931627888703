/**
 * Our responsive-image-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage} from 'gatsby-plugin-image';
import ResponsiveImageProps from './ResponsiveImage.props';

// Assets
import fallback from 'images/fallback.svg';

const ResponsiveImage = ({image, ...props}) => {
    // Use responsive-image
    if (image && image.imageFile && image.imageFile.childImageSharp) {
        return <GatsbyImage image={image.imageFile.childImageSharp.gatsbyImageData} alt={image.altText} {...props}/>;
    }

    // Use normal image
    if (image && (image.gatsbyFileUrl || image.mediaItemUrl)) {
        return <img
            src={image.gatsbyFileUrl || image.mediaItemUrl}
            alt={image.altText}
            style={{maxWidth: '100%'}}
            {...props}/>
    }

    // Image not found
    return <img
        src={fallback}
        alt={"Fallback"}
        style={{maxWidth: '100%', objectFit: 'cover'}}
        {...props}/>;
};

ResponsiveImage.propTypes = {
    image: PropTypes.shape(ResponsiveImageProps).isRequired,
};

export default ResponsiveImage;

/**
 * Props for our content-blocks
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import PropTypes from 'prop-types';
import {ResponsiveImageProps} from '../ResponsiveImage';

const BlockShape = {
    __typename: PropTypes.string,
    rowSettings: PropTypes.shape({
        background: PropTypes.string,
        rowId: PropTypes.string,
        bgImage: PropTypes.shape(ResponsiveImageProps),
    }),
};

export default BlockShape;

/**
 * Props for wordpress-file-links
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import PropTypes from 'prop-types';

const WordpressFileProps = {
    mediaItemUrl: PropTypes.string.isRequired,
    sourceUrl: PropTypes.string.isRequired,
    gatsbyFileUrl: PropTypes.string,
};

export default WordpressFileProps;

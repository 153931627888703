/**
 * Our helper for local links
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */

import React from 'react';
import {withPrefix} from 'gatsby';
import {Globals} from './Store';

/**
 * Creates a local link from an url
 *
 * @param {string }url
 * @param {boolean} addPrefix (optional, default: false)
 * @param {boolean} replaceParameters (optional, default: true)
 * @returns {string} The local url
 */
const CreateLocalLink = (url = '', addPrefix = false, replaceParameters = true) => {
    const {wpUrl} = React.useContext(Globals);
    let link = url.replace(wpUrl, '').replace('/app/uploads/', '/fs/');
    link = replaceParameters ? link.replace(/[?=]/, '_') : link;
    return addPrefix ? withPrefix(link) : link;
};

export default CreateLocalLink;

/**
 * Data-Query for our peritus-link
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import WordpressFileProps from 'utilities/WordpressFile/WorpressFile.props';
import PropTypes from 'prop-types';

const PeritusLinkProps = {
  linktext: PropTypes.string,
  linktype: PropTypes.string,
  internalurl: PropTypes.shape({
    uri: PropTypes.string,
  }),
  externalurl: PropTypes.string,
  fileurl: PropTypes.shape({
    title: PropTypes.string,
    ...WordpressFileProps
  }),
  hash: PropTypes.string,
  target: PropTypes.bool,
};

export default PeritusLinkProps;

/**
 * Language selector component
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
// NPM
import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Peritus
import Languages from './Languages';

// Styles
import * as styles from './lang-selector.module.scss';

class LangSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLangSelectorActive: false,
        };
    }

    /**
     * Change
     */
    toggleLangSelector() {
        this.setState({
            isLangSelectorActive: !this.state.isLangSelectorActive,
        });
    }

    render() {
        const langListClasses = classNames({
            [styles.langList]: true,
            [styles.active]: !!this.state.isLangSelectorActive,
        });

        const langSelectorClasses = classNames({
            [styles.langSelector]: true,
            [styles.active]: !!this.state.isLangSelectorActive,
        });

        return (
            <div onClick={() => this.toggleLangSelector()} className={langSelectorClasses}>
                <Languages langListClasses={langListClasses} styles={styles} {...this.props}/>
            </div>
        );
    }
}

LangSelector.propTypes = {
    translations: PropTypes.arrayOf(PropTypes.shape({
        uri: PropTypes.string,
        language: PropTypes.shape({
            slug: PropTypes.string,
        }),
    })),
    current: PropTypes.string.isRequired,
};

export default LangSelector;

/**
 * Our hamburger-button
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * TODO: Translate "Toggle Navigation"
 */
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './hamburger.module.scss';

const Hamburger = ({toggleMenu, isMenuActive}) => (
    <button type="button"
        aria-label="Toggle Navigation"
        onClick={() => toggleMenu()}
        className={`${styles.burger} ${isMenuActive ? styles.active : ''}`}
    >
        <span className={styles.meat}/>
    </button>
);

Hamburger.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    isMenuActive: PropTypes.bool,
};

Hamburger.defaultProps = {
    isMenuActive: false,
};

export default Hamburger;

// extracted by mini-css-extract-plugin
export var cookieConsent = "cookie-consent-module--cookie-consent--4YN6A";
export var inner = "cookie-consent-module--inner--P3ojC";
export var title = "cookie-consent-module--title--2+6KF";
export var more = "cookie-consent-module--more--C8KOs";
export var moreEl = "cookie-consent-module--more-el--eSQVR";
export var options = "cookie-consent-module--options--PtIoH";
export var option = "cookie-consent-module--option--awqKu";
export var save = "cookie-consent-module--save--MZI6r";
export var details = "cookie-consent-module--details--EB-KR";
export var buttons = "cookie-consent-module--buttons--DRAit";
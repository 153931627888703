/**
 * LanguageHelper
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React, {Component} from 'react';
import {Link, navigate} from 'gatsby';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';

// Peritus
import {Globals} from 'utilities/Store';

// Assets
import TriangleIcon from './triangle.inline.svg';

class Languages extends Component {
    static contextType = Globals;

    constructor(props, context) {
        super(props, context);
        this.isNeeded = this.context.languages.length > 1;

        if (!this.isNeeded) {
            return;
        }

        // Prepare available languages
        this.availableLanguages = this.context.languages.map((lang) => {
            return lang.slug;
        });

        // Get the cookie
        this.cookieName = 'user-lang';
        this.defaultSlug = this.context.defaultLanguage.slug;

        // Prepare translations
        this.translations = this.props.translations.reduce((map, translation) => {
            map[translation.language.slug] = translation.uri;
            return map;
        }, {});

        // Get current lang-object
        this.currentLang = this.context.languages.reduce((currentObject, lang) => {
            if (this.props.current === lang.slug) {
                return lang;
            }

            return currentObject;
        }, {});
    }

    /**
     * Redirect if needed
     */
    componentDidMount() {
        this.langSelection = cookie.load(this.cookieName) ?? navigator.language.substr(0, 2);

        if (this.isBot() || this.props.current === this.langSelection) {
            return;
        }

        if (this.getLangUrl(this.langSelection) !== window.location.pathname) {
            this.replaceCookie(this.langSelection);
            navigate(this.getLangUrl(this.langSelection), {replace: true});
        }
    }

    /**
     * Build a lang-url
     *
     * @param {string} langSlug
     *
     * @return {string}
     */
    getLangUrl(langSlug) {
        return this.translations[langSlug]
            ? (
                '/' === this.translations[langSlug] && langSlug !== this.defaultSlug
                    ? `/${langSlug}/`
                    : this.translations[langSlug]
            )
            : (
                langSlug !== this.defaultSlug && this.availableLanguages.includes(langSlug)
                    ? `/${langSlug}/`
                    : '/'
            );
    }

    /**
     * Replace language-cookie
     *
     * @param {string} langSlug
     */
    replaceCookie(langSlug) {
        cookie.save(this.cookieName, langSlug, {path: '/'});
    }

    /**
     * Check if the current user is a bot
     *
     * @return {boolean}
     */
    isBot() {
        return window.navigator
            && /bot|googlebot|crawler|spider|robot|crawling|lighthouse/i.test(window.navigator.userAgent);
    }

    /**
     * Render the language-selector
     *
     * @return {JSX.Element}
     */
    render() {
        if (!this.isNeeded) {
            return null;
        }

        return (<>
            {this.currentLang.code}
            <TriangleIcon/>
            <ul className={this.props.langListClasses}>
                {
                    this.context.languages.map((lang, key) => {
                        if (lang.slug === this.currentLang.slug) {
                            return;
                        }

                        return (<li className={this.props.styles.langItem} key={key}>
                            <Link
                                className={this.props.styles.langLink}
                                to={this.getLangUrl(lang.slug)}
                                title={lang.name}
                                onClick={() => this.replaceCookie(lang.slug)}
                            >{lang.code}</Link>
                        </li>);
                    })
                }
            </ul>
        </>);
    }
}

Languages.propTypes = {
    translations: PropTypes.arrayOf(PropTypes.shape({
        uri: PropTypes.string,
        language: PropTypes.shape({
            slug: PropTypes.string,
        }),
    })),
    current: PropTypes.string.isRequired,
};

export default Languages;

/**
 * Our button-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './button.module.scss'

const TextButton = ({buttonText, textButtonStyles, extraClasses, ...props}) => {
    const buttonClasses = classNames({
        [styles.textButton]: true,
        [extraClasses]: !!extraClasses,
    });

    return (<button className={buttonClasses} {...props}>{buttonText}</button>)
};

TextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    extraClasses: PropTypes.string,
};

TextButton.defaultProps = {
    extraClasses: '',
};

export default TextButton;

/**
 * Our default block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';

const Default = ({blockType}) => (
    <div>The block you're using is not implemented: {blockType}</div>
);

export default Default;

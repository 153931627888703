/**
 * Our button-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './button.module.scss'

const Button = ({buttonText, style, size, extraClasses, ...props}) => {
    const buttonClasses = classNames({
        [styles.button]: true,
        [styles[style]]: !!styles[style],
        [styles[size]]: !!styles[size],
        [extraClasses]: !!extraClasses,
    });

    return (<button className={buttonClasses} {...props}>{buttonText}</button>)
};

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    style: PropTypes.string,
    size: PropTypes.string,
    extraClasses: PropTypes.string,
};

Button.defaultProps = {
    style: '',
    size: '',
    extraClasses: '',
};

export default Button;

/**
 * Our header-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Menu from './Menu/Menu';
import MainLogo from './MainLogo';
import * as styles from './header.module.scss';
import Hamburger from './Hamburger/Hamburger';
import LangSelector from '../Languages/LangSelector';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuActive: false,
        };
    }

    /**
     * Change
     */
    toggleMenu() {
        this.setState({
            isMenuActive: !this.state.isMenuActive,
        });
    }

    render() {
        const homeUrl = 'en' === this.props.language.slug ? '/' : `/${this.props.language.slug}/`;

        return (
            <header className={styles.mainHeader}>
                <MainLogo siteTitle={this.props.siteTitle} url={homeUrl}/>
                <Menu isActive={this.state.isMenuActive}/>
                <LangSelector translations={this.props.translations} current={this.props.language.slug}/>
                <Hamburger toggleMenu={this.toggleMenu.bind(this)} isMenuActive={this.state.isMenuActive}/>
            </header>
        );
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
    translations: PropTypes.arrayOf(PropTypes.shape({
        uri: PropTypes.string,
        language: PropTypes.shape({
            slug: PropTypes.string,
        }),
    })),
    language: PropTypes.shape({
        slug: PropTypes.string,
    }),
};

Header.defaultProps = {
    siteTitle: '',
};

export default Header;

/**
 * Our menu-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from './MenuItem';
import * as styles from './menu.module.scss';
import {Globals} from 'utilities/Store';

const Menu = ({isActive}) => {
    const {mainMenu} = React.useContext(Globals);
    const containerClasses = classNames({
        [styles.container]: true,
        [styles.active]: !!isActive,
    });

    return (
        <nav className={containerClasses}>
            <ul className={styles.menu}>
                {
                    mainMenu &&
                    mainMenu.map((menuItem, index) => (
                        <MenuItem key={index} {...menuItem}/>
                    ))
                }
            </ul>
        </nav>
    );
};

Menu.propTypes = {
    isActive: PropTypes.bool.isRequired,
};

Menu.defaultProps = {
    isActive: false,
};

export default Menu;

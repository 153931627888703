/**
 * Our footer-Menu
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import * as styles from './footer-menu.module.scss';
import MenuLink from '../../MenuLink/MenuLink';
import {Globals} from '../../../utilities/Store';

const FooterMenu = () => {
    const {footerMenu} = React.useContext(Globals);
    return (<nav id={'footer-nav'} className={styles.footerNav}>
        {
            footerMenu.map((menuItem, index) => (
                <MenuLink key={index} className={styles.link} activeClass={styles.active} {...menuItem}/>
            ))
        }
    </nav>);
};

export default FooterMenu;
